.print-security-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  min-width: 48%;
  width: max-content;
  min-height: 200px;
  padding: 20px 0;
}

.small-content {
  float: left;
}

.print-security-content.large-content {
  height: 100%;
  margin: auto;
  padding: 0;
}

.print-security-watermark {
  padding-top: 5px;
  display: flex;
  gap: 5px;
}

.print-security-logo {
  width: 70px;
}

.page-break.security-page-break {
  margin: 0;
}

@media print {
  .print-security-content {
    break-before: column;
  }
}
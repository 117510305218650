
.custom-picky .picky__filter__input {
  padding: 1.5rem 1rem;
  font-size: 1rem;
  color: #676767;
  font-family: 'Open Sans';
}

.custom-picky .picky__filter__input:focus {
  outline: 1px solid #1E7B3E;
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
}

.custom-picky button.picky__input:hover, .custom-picky button.picky__input:focus {
  border: 1px solid #1E7B3E;
}

.custom-picky .picky__dropdown .option.selected,
.custom-picky .picky__dropdown li.selected {
  background-color: rgba(240, 240, 240, 0.7);
}

.custom-picky .picky__dropdown .option:focus, .picky__dropdown li:focus {
  outline: none !important;
}

.custom-picky .picky__dropdown li {
  border-bottom: none;
}
.custom-picky .picky__dropdown .option:hover, .custom-picky .picky__dropdown li:hover {
  background-color: rgba(240, 240, 240, 0.7);
}

.custom-picky .picky__placeholder {
  color: #7b7b7b;
  font-family: 'Open Sans';
}

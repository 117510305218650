.code-grid-print-container {
  padding: 1rem;
}

.code-grid {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(5, 144px);
  width: max-content;
  margin: auto;
  padding-top: 10px;
}

.qr-container {
  --border: 1px solid black;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
  height: 76px;
  border: var(--border);
  border-right: var(--border);
  border-bottom: var(--border);
}

.qr-container:nth-child(-n + 5) {
  border-top: var(--border);
}

.qr-container:nth-child(5n + 1) {
  border-left: var(--border);
}

.code-label-container {
  font-family: Calibri, 'Open Sans', sans-serif;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  word-break: break-all; 
}

.page-break {
  margin-top: 1rem;
  display: block;
  break-before: page;
  page-break-before: always;
}

.page-break-preview-ellipse {
  padding: 1rem;
  text-align: center;
  font-size: 30px;
}
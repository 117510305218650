.multi-select {
  --rmsc-main: #e2e6ea;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 38px; /* Height */
}

.picky__input::after {
  margin-top: 10px !important;
}

.picky__input:hover {
  border-color: black !important;
}

/* https://www.npmjs.com/package/react-to-print */
/* @media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
} */

.print-container {
  margin: 0;
  padding: 0;
}

.print-page {
  margin: 0;
  padding: 0;
}

.print-content {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95%;
}

.print-qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.print-footer {
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid #D9D9D9;
}

.print-footer .descriptions-container {
  display: flex;
  justify-content: space-between;
  padding: 0 6rem;
}

.print-footer .description {
  width: 50%;
  font-size: 24px;
  font-family: 'Open Sans';
}

.print-footer .text {
  margin: 10px 0px;
}

.print-footer .title {
  font-weight: 600;
}

.print-watermark {
  padding: 20px 0;
}

@media print {
  .page-break {
    display: block;
    break-before: page;
    page-break-before: always;
  }
}

/* @page {
  size: auto;
  margin: 20mm;
} */
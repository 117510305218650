.ql-font-open-sans {
  font-family: 'Open Sans';
}

.quill.rich-editor {
  display: flex;
  flex-direction: column-reverse;
  background-color: white;
  border: none;
  flex: 1;
  display: flex;
  height: 100%;
}

.ql-snow.ql-container {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: none;
  max-width: 100%;

  flex: 1;
}

.ql-snow .ql-editor {
  font-size: 18px;
  font-family: 'Open Sans';
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow-x: auto;
  word-wrap: break-word;
  word-break: break-all;
  line-break: auto;
  border: 1px solid #D9D9D9;
  height: 250px;
  max-height: 250px;
}

.ql-snow.ql-toolbar {
  border: 1px solid #D9D9D9;
  border-top: none;
}

.ql-snow .ql-editor:focus {
  border: 1px solid #1E7B3E;
}

[aria-errormessage] .ql-snow .ql-editor:focus {
  border: 1px solid #FF0707;
}
circle, rect {
  --gray-color: #d9d9d9;
  --white-color: white;
  --root-rect-width: 650px;
  --root-rect-height: 56px;
  --child-circle-radius: 36px;
  --child-circle-stroke-width: 0px;
  --child-rect-width: 300px;
  --child-rect-height: 70px;
}

text {
  --font-size: 1em;
}

.fullscreen {
  --gray-color: #f5f5f5;
  --root-rect-width: 800px;
  --root-rect-height: 60px;
  --font-size: 1.15em;
}

/* shapes */
rect.node__root {
  width: var(--root-rect-width);
  height: var(--root-rect-height);
  x: calc(-1 * var(--root-rect-width) / 2);
  y: calc(-1 * var(--root-rect-height) / 2);
  fill: var(--gray-color);
  stroke: var(--gray-color);
}

rect.node__child {
  width: var(--child-rect-width);
  height: var(--child-rect-height);
  x: calc(-1 * var(--child-rect-width) / 2);
  y: calc(-1 * var(--child-rect-height) / 2);
  fill: var(--gray-color);
  stroke: var(--gray-color);
}

circle.node__child {
  r: var(--child-circle-radius);
  stroke-width: var(--child-circle-stroke-width);
  stroke: var(--gray-color);
  fill: var(--gray-color);
}

/* text */
text.node__root, text.node__child {
  text-anchor: middle;
  stroke: black;
  fill: black;
  stroke-width: 0px;
  font-family: "Open Sans";
  transform-box: fill-box;
  transform-origin: center;
  transform: translateY(25%);
  font-size: var(--font-size);
}

/* tooltip */
.node__tooltip {
  --tooltip-width: 200px;
  --tooltip-height: 50px;
  --tooltip-arrow-width: 8px;
}

rect.node__tooltip.box {
  width: var(--tooltip-width);
  height: var(--tooltip-height);
  x: calc(-1 * var(--tooltip-width) / 2);
  y: calc(-1 * var(--tooltip-height) * 1.75);
  fill: var(--white-color);
  stroke: var(--gray-color);
  stroke-width: 0.5px;
  filter: drop-shadow(0 3px 3px rgba(0, 0, 0, 0.16));
}

rect.node__tooltip.arrow {
  width: var(--tooltip-arrow-width);
  height: var(--tooltip-arrow-width);
  x: calc(-1 * var(--tooltip-arrow-width) / 2);
  y: calc(-1 * var(--tooltip-height) / 1.2);
  stroke: var(--gray-color);
  stroke-width: 0px;
  fill: white;
  line-height: 0;
  transform-box: fill-box;
  transform-origin: center;
  transform: rotate(45deg);
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.16));
}

text.node__tooltip {
  text-anchor: middle;
  stroke: black;
  fill: black;
  stroke-width: 0px;
  font-family: 'Open Sans';
  transform-box: fill-box;
  transform: translateY(-250%);
}

/* links */
.tree-path-line {
  fill: none;
  stroke-width: 1px;
}